import React from 'react';
// import { graphql } from 'gatsby';
import SEO from 'components/SEO/SEO';
import FullWidthBox from 'components/FullWidthBox';
import StyledBox from 'components/StyledBox';

const NotFound = () => {
  return (
    <>
      {/* <SEO
        title={page.seo.title}
        description={page.seo.description}
        image={page.seo.description}
        pathname={location.pathname}
      /> */}
      <StyledBox>
        <article>
          <FullWidthBox style={{ marginTop: '100px' }} pad="large">
            <StyledBox
              className="align-c justify-c"
              margin={{ bottom: 'large' }}
            >
              <h1 className="xlarge" margin="none">
                {/* {page.title} */}
              </h1>
              <p
                className="xlarge"
                style={{ maxWidth: '100%', textAlign: 'center' }}
                // dangerouslySetInnerHTML={{ __html: page.description }}
              />
            </StyledBox>
          </FullWidthBox>
        </article>
      </StyledBox>
    </>
  );
};

// export const query = graphql`
//   query NotFound {
//     # page: tranlationJson(locale: { eq: "en" }, name: { eq: "404" }) {
//     #   title
//     #   htmlTitle
//     #   seo {
//     #     description
//     #     follow
//     #     keywords
//     #     title
//     #   }
//     #   heroImages {
//     #     alt
//     #     src
//     #     title
//     #   }
//     #   description
//     #   descriptionFound
//     # }
//   }
// `;

export default NotFound;
